import React, { Component } from "react";
import "./css/style.css";
import TeamList from "../components/ourTeam/teamList";
import ProfilePicture from "../assets/images/team/Profile_Picture.png";

function OurTeam(props) {

    const teamList = [
        {id: 1, Name: "Joydeep Mahato", image: ProfilePicture, designation: "CEO & Founder", isCEO: true},
        {id: 2, Name: "Rajib Dutta", image: ProfilePicture, designation: "SEO Team Lead", isCEO: false},
        {id: 3, Name: "Pinaki Pati", image: ProfilePicture, designation: "SEO, Outreach", isCEO: false},
        {id: 4, Name: "Abhishek Kundu", image: ProfilePicture, designation: "SEO, Link Building Strategist", isCEO: false},
    ];

        return (
            <div>
                <div className="service-header">
                    <span>Our Team</span>
                </div>

                <div>
                    <div className="intro">
                        <h3 className="intro-header"> WHO WE ARE </h3>
                        <p className="intro-body"> 
                            We are a team of experts having practical knowledge and years of experience to optimize your website.
                            <br/>
                            We provide Natural, relevant, in-content links, from 100% real bloggers.
                        </p>
                    </div>

                    <TeamList teamList={teamList}/>
                </div>
            </div>
        );
}

export default OurTeam;