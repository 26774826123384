import React, { Component } from "react";
import "./contact.css";
import Call from "../../assets/icons/call.svg";
import Mail from "../../assets/icons/mail.svg";

function CallEmailSection(props) {

        return (
            <div className="call-mail">
                <div className="call-section">
                    <div className="icon-box bg-primary d-flex align-items-center justify-content-center rounded">
                        {/* <Call/> */}
                        <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="35" fill="white">
                            <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"/>
                        </svg>
                    </div>
                    <div className="ps-4 pl-3">
                        <h5 className="mb-2">Call to ask any question</h5>
                        <h4 className="text-primary mb-0">+91 7001753575</h4>
                    </div>
                </div>

                <div className="mail-section">
                    <div className="icon-box bg-primary d-flex align-items-center justify-content-center rounded">
                        {/* <Mail/> */}
                        <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="37" fill="white">
                            <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/>
                        </svg>
                    </div>
                    <div className="ps-4 pl-3">
                        <h5 className="mb-2">Email to get free quote</h5>
                        <h4 className="text-primary mb-0">fastrankerofficial@gmail.com</h4>
                    </div>
                </div>
            </div>
        );
}

export default CallEmailSection;