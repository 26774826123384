import React, { Component } from "react";
import "./team.css";
import Member from "./member";

function TeamList(props) {

        return (
            <div className="team-outer">
                <div className="ceo-section">
                     {props.teamList.map((member =>
                        (member.isCEO &&
                         <Member key={member.id} member={member}/>
                        )
                     ))}
                </div>
                <div className="team-section">
                     {props.teamList.map((member =>
                        (!member.isCEO &&
                         <Member key={member.id} member={member}/>
                        )
                     ))}
                </div>
            </div>
        );
}

export default TeamList;