import React, { Component } from "react";
import "./fourthBlock.css";
import FourthBlockImg from "../../assets/images/FourthBlockImage.png"

function FourthBlock(props) {

        return (
            <div className="fourth-container-main">
                <div className="fourth-container-inner">
                    <div className="fourth-container-text">
                        <div>
                            <h1>
                                Our team of experts is here to help
                            </h1>
                            <p>
                                {/* We are a team of experts having practical knowledge and years of experience to optimize your website. We provide Natural, relevant, in-content links, from 100% real bloggers. */}
                                With a team of experienced professionals dedicated to driving your online success, we specialize in maximizing your digital visibility and increasing your website's organic traffic.
                                Discover the power of effective SEO with us.
                            </p>
                            <div className="exp-peo">
                                <span className="exp-peo-text"> Experience </span>
                                <span className="exp-peo-number"> 6+ </span>
                            </div>
                            <div className="exp-peo">
                                <span className="exp-peo-text"> People </span>
                                <span className="exp-peo-number"> 50+ </span>
                            </div>
                        </div>
                    </div>
                    <div className="fourth-container-img">
                        <div>
                            <img src={FourthBlockImg} alt="image" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default FourthBlock;