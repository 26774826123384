import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/navBar';
import Footer from './components/footer';
import Home from './pages/home';
import OurTeam from './pages/ourTeam';
import Services from './pages/services';
import Contact from './pages/contact';


function App() {
  return (
    <div className="App">
       <BrowserRouter>
          <NavBar/>
          <Routes>
             <Route exact path='/' name="Home" element={ <Home/> } />
             <Route exact path='/index.html' name="Home" element={ <Home/> } />
             <Route exact path='/ourteam' name="Our Team" element={ <OurTeam/> } />
             <Route exact path='/services' name="Services" element={ <Services/> } />
             <Route exact path='/contact' name="contact" element={ <Contact/> } />
          </Routes>
          <Footer/>
       </BrowserRouter>
    </div>
  );
}

export default App;
