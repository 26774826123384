import React, { Component } from "react";
import "./contact.css";

function ContactForm(props) {

        return (
            <div className="form">
                <input className="name" type="text" placeholder="Your Name"/>
                <input className="email" type="text" placeholder="Your Email" /> 
                <br/>
                <input className="mobile" type="text" placeholder="Mobile" /> 
                <br/>
                <textarea className="message" placeholder="Message"></textarea>
                <button className="send-btn btn btn-primary btn-lg"><b>Send</b></button>
            </div>
        );
}

export default ContactForm;