import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./secondBlock.css";
import SecondBlockImg from "../../assets/images/SecondBlockImage.png"

function SecondBlock(props) {

        return (
            <div className="second-container-main">
                <div className="second-container-inner">
                    <div className="second-container-img">
                        <div>
                            <img src={SecondBlockImg} alt="image" width="100%" />
                        </div>
                    </div>
                    <div className="second-container-text">
                        <div>
                            <h1>
                                Why Choose Us?
                            </h1>
                            <p>
                                Our digital marketing services are tailored to your needs, ensuring maximum ROI. 
                                With a focus on results, we help your brand stand out in the competitive online landscape, driving growth and success for your business.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default SecondBlock;