import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./firstBlock.css";
import FirstBlockImg from "../../assets/images/FirstBlockImage.png"

function FirstBlock(props) {

        return (
            <div className="first-container-main">
                <div className="first-container-inner"> 
                    <div className="first-container-text">
                        <div>
                            <span>Empower Your Online Potential:</span>
                            <h1>
                               Elevate Your Brand with Expert SEO, Link Building,
                               <br/>
                               & Content Strategies.
                            </h1>
                            <p>Trust FastRanker for Exceptional Digital Marketing Solutions and Results!</p>
                            <Link className="btn btn-primary btn-lg rounded-pill" to="/contact"><b>Contact Us Today</b></Link>
                        </div>
                    </div>
                    <div className="first-container-img">
                        <div>
                            <img src={FirstBlockImg} alt="image" width="520px" height="470px"/>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default FirstBlock;