import React, { Component } from "react";
import "./css/style.css";
import ServiceList from "../components/services/serviceList";
import SEOImg from "../assets/images/services/SEO.png";
import LBBImg from "../assets/images/services/LBB.png";
import CMImg from "../assets/images/services/CM.png";
import BOImg from "../assets/images/services/BO.png";

function Services(props) {

    const serviceList = [
        {id: 1, title: "SEO", image: SEOImg, description: "The importance of search engine optimization (SEO) cannot be ignored today."},
        {id: 2, title: "Link Building & Backlinks", image: LBBImg, description: "Link building is essentially about creating as many “thumbs ups” for your website as possible."},
        {id: 3, title: "Content Marketing", image: CMImg, description: "We have a team of experienced, seasoned native writers who can produce content for your project."},
        {id: 4, title: "Bloger Outreach", image: BOImg, description: "Blogger outreach is the process of strategically communicating and building relationships with industry influencers and publishers to achieve a specific goal."},
    ];

        return (
            <div>
                <div className="service-header">
                    <span>Our Services</span>
                </div>

                <div>
                    <ServiceList serviceList={serviceList}/>
                </div>
            </div>
        );
}

export default Services;