import React, { Component } from "react";
import "./css/style.css";
import CallEmailSection from "../components/contact/callEmailSection.js";
import ContactForm from "../components/contact/contactForm.js";

function Contact(props) {

        return (
            <div>
                <div className="section-title text-center mx-auto">
                    <h1 className="mb-0">Have Any Query, Feel Free To Contact Us</h1>
                </div>

                <CallEmailSection/>
                <ContactForm/>
            </div>
        );
}

export default Contact;