import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import Facebook from "../assets/icons/facebook.svg";
import Linkedin from "../assets/icons/linkedin.svg";

function Footer(props) {

        return (
            <footer className="footer bg-light">

                <div className="container justify-content-between">

                    <div className="footer-title">
                        <h1> FAST RANKER </h1>
                        <p>
                            Boost your online presence and traffic with our expert SEO team. Experience digital success with us.
                        </p>
                        <div className="social-links">
                            <Link to="https://www.facebook.com/p/Fast-Ranker-61558050664139/">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="35px" height="35px">
                                    <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.8 90.7 226.4 209.3 245V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V501C413.3 482.4 504 379.8 504 256z"/>
                                </svg>
                            </Link>
                            <Link to="https://www.linkedin.com/company/fastrankerofficial/">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="35px" height="35px">
                                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    
                    <div class="footer-links">
                        <h4>Links</h4>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/ourteam">Our team</Link></li>
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/contact">Contacts</Link></li>
                            <li><Link to="/contact">Let's Talk</Link></li>
                        </ul>
                    </div>

                    <div class="footer-services">
                        <h4>Services</h4>
                        <ul>
                            <li><Link to="/services">SEO</Link></li>
                            <li><Link to="/services">Link Building & Backlinks</Link></li>
                            <li><Link to="/services">Content Marketing</Link></li>
                            <li><Link to="/services">Blogger Outreach</Link></li>        
                        </ul>
                    </div>

                    <div class="footer-office">
                        <h4>Office</h4>
                        <ul>
                            <li>India</li>
                            <li>CEO : Joydeep Mahato</li>
                            <li>Address: Banksimul, P.O-Banksimul, Kamalpur, Bankura, Kamalpur, West Bengal- 722137. </li>
                            <li><Link to="mailto:fastrankerofficial@gmail.com">fastrankerofficial@gmail.com</Link></li>
                            <li><Link to="/contact">+91 7001753575</Link></li>
                        </ul>
                    </div>
                      
                </div>

                <div className="footer-bottom">
                    <span className="text-center">
                        Copyright &copy;{ new Date().getFullYear() } All rights reserved by Fast Ranker. 
                    </span>
                </div>

            </footer>
        );
}

export default Footer;