import React, { Component } from "react";
import "./services.css";
import Service from "./service";

function ServiceList(props) {

        return (
            <div className="service-list">
                {props.serviceList.map((service =>
                    <Service className="service" key={service.id} service={service}/>
                ))}
            </div>
        );
}

export default ServiceList;