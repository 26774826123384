import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./thirdBlock.css";
import SEOImg from "../../assets/images/services/SEO.png";
import LBBImg from "../../assets/images/services/LBB.png";
import CMImg from "../../assets/images/services/CM.png";
import BOImg from "../../assets/images/services/BO.png";

function ThirdBlock(props) {

        return (
            <div className="third-container-main">
                <div className="third-container-title">
                    <span>Services</span>
                </div>
                
                <div className="third-container-inner">

                    <div className="third-container-block">
                        <div>
                            <div className="block-img">
                                 <img src={SEOImg} alt="image" width="100%" />
                            </div>
                            <div className="block-text">
                                 <Link to="/services"> SEO </Link>
                            </div>
                        </div>
                    </div>

                    <div className="third-container-block">
                        <div>
                            <div className="block-img">
                                 <img src={LBBImg} alt="image" width="100%" />
                            </div>
                            <div className="block-text">
                                 <Link to="/services"> Link Building & Backlinks </Link>
                            </div>
                        </div>
                    </div>

                    <div className="third-container-block">
                        <div>
                            <div className="block-img">
                                 <img src={CMImg} alt="image" width="100%" />
                            </div>
                            <div className="block-text">
                                 <Link to="/services"> Content Marketing </Link>
                            </div>
                        </div>
                    </div>

                    <div className="third-container-block">
                        <div>
                            <div className="block-img">
                                 <img src={BOImg} alt="image" width="100%" />
                            </div>
                            <div className="block-text">
                                 <Link to="/services"> Bloger Outreach </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
}

export default ThirdBlock;