import React, { Component } from "react";
import "./css/style.css";
import FirstBlock from '../components/home/firstBlock';
import SecondBlock from "../components/home/secondBlock";
import ThirdBlock from "../components/home/thirdBlock";
import FourthBlock from "../components/home/fourthBlock";
import FifthBlock from "../components/home/fifthBlock";

function Home(props) {

        return (
            <div>
                <FirstBlock/>
                <SecondBlock/>
                <ThirdBlock/>
                <FourthBlock/>
                <FifthBlock/>
            </div>
        );
}

export default Home;