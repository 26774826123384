import React, { Component } from "react";
import "./services.css";

function Service(props) {

        return (
            <div className="service-container">
                <div className="service-img">
                    <img src={props.service.image} alt="service_image" width="100%" />
                </div>
                <div className="service-title">
                    {props.service.title}
                </div>
                <div className="service-description">
                    {props.service.description}
                </div>
            </div>
        );
}

export default Service;