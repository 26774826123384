import React, { Component } from "react";
import "./team.css";

function Member(props) {

        return (
            <div className="member">
                <img className="member-img" src={props.member.image} alt="member-image"/>
                <div className="member-name"> {props.member.Name} </div>
                <div className="member-exp"> {props.member.designation} </div>
            </div>
        );
}

export default Member;