import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./navBar.css";
import Logo from "../assets/icons/Logo.png";

function NavBar(props) {

        const [showMenu, setShowMenu] = useState(false);

        const toggleMenu = () => {
           setShowMenu(!showMenu);
        };
   
        const closeMenuOnMobile = () => {
           if (window.innerWidth <= 1150) {
              setShowMenu(false);
           }
        };

        return (
         <>
          <header className="header">
            <nav className="navbar navbar-light bg-light">
                <NavLink className="navbar-brand m-2 ml-4" to='/' onClick={closeMenuOnMobile}>
                    <img className="logo" src={Logo} alt="logo" width="50px" />
                    <span className="nav-brand"> FAST RANKER </span>
                </NavLink>

                <div className={`nav-menu ${showMenu ? "show-menu" : ""}`}>
                    <div className="nav-list">
                        <NavLink className="nav-item" to='/' onClick={closeMenuOnMobile}>
                            HOME
                        </NavLink>
                        <NavLink className="nav-item" to='/ourteam' onClick={closeMenuOnMobile}>
                            OUR TEAM
                        </NavLink>
                        <NavLink className="nav-item" to='/services' onClick={closeMenuOnMobile}>
                            SERVICES
                        </NavLink>
                        <NavLink className="nav-item" to='/contact' onClick={closeMenuOnMobile}>
                            CONTACT
                        </NavLink>
                        <NavLink className="btn btn-primary rounded-pill" to='/contact' onClick={closeMenuOnMobile}>
                            <b>LET'S TALK</b>
                        </NavLink>
                    </div>
 
                    <div className="nav-close" id="nav-close" onClick={toggleMenu}>
                        <IoClose />
                    </div>
                </div>

                <div className="nav-toggle" id="nav-toggle" onClick={toggleMenu}>
                    <IoMenu />
                </div>
            </nav>
          </header> 
          <div className="false-space"> </div>
         </>
        );
}

export default NavBar;