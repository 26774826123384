import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./fifthBlock.css";

function FifthBlock(props) {

        return (
            <div className="fifth-container-main">
                <div className="fifth-container-inner">
                     <h1> We are always ready to help you. </h1>
                     <p>Feel free to call us</p>
                     <Link to="/contact">Contact Us</Link>
                </div>
            </div>
        );
}

export default FifthBlock;